import React from 'react'

import Layout from '../../components/Layout'
import { Header } from '../../components/Header'
import ProductList from '../../components/ProductList'

export default class ProductsIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Header title="Products" subheading="" image="/img/kailash-three-bottles.webp" />
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
            <div className="section">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              Products
            </h2>
                <div style={ { paddingBottom: '2em' }}>
                  Beautiful copper water bottles, mugs, cups, glasses and straws. Our Copper items not only look stunning but copper naturally keeps your water clean, cool and fresh as well as infusing it with wonderful health benefits to improve your well-being, see <a href="/about">here</a> for more details.
                </div>
              <ProductList />
            </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
