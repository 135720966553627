import React from 'react'
import PropTypes from 'prop-types'
import { formatPrice } from './NumberFormatting';

export const Pricing = ({ price, weight = '', showWeight = false }) => (
  <div>{formatPrice(price)}{(showWeight) ? `(${weight}g)` : ''}</div>
)

Pricing.propTypes = {
  data: PropTypes.shape({
    weight: PropTypes.number,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
}

export default Pricing
