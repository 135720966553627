import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
// import { HTMLContent } from './Content'
import { Pricing } from './Pricing';

const ProductLink = ({ product, content, size = 4 }) => (
  <Link
    className={`title has-text-primary is-size-${size}`}
    to={product.fields.slug}
  >
    {content}
  </Link>
)

class ProductsList extends React.Component {
  render() {
    const { data } = this.props
    const { edges: products } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {products &&
          products.map(({ node: product }) => (
            <div className="is-parent column is-6 has-text-centered" key={product.id} style={ { marginBottom: '1em' } }>
              <div><ProductLink product={product} content={product.frontmatter.heading} size="4" /></div>
              <div className="is-size-6"><a href={product.fields.slug} style={ { textDecoration: 'none', color: '#B77729' } }><Pricing price={product.frontmatter.price} showWeight={false} /></a></div>
              <div style={ { marginTop: '1em' } }><ProductLink product={product} content={(<img alt={product.frontmatter.title} style={ { width: "10em" } } src={product.frontmatter.image.childImageSharp.fluid.src} />)} /></div>
            </div>
          ))}
      </div>
    )
  }
}

ProductsList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ProductsQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___order] }
          filter: { frontmatter: { templateKey: { eq: "product-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                heading
                image {
                  childImageSharp {
                    fluid(maxWidth: 640, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                order
                price
                weight
                sku
                variants {
                  code
                  name
                  instock
                  order
                }
              }
              html
            }
          }
        }
      }
    `}
    render={(data, count) => <ProductsList data={data} count={count} />}
  />
)
